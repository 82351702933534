<template>
    <button
        id="OLYMPICS"
        name="OLYMPICS"
        class="btn"
        :data-command="$t('Olympics')"
    >
        <img src="/images/icons/OlympicsNavy.svg" alt="Paris Olympics" role="img" aria-hidden="true" />
        <label>{{ $t('Olympics') }}</label>
    </button>
</template>
<script>
export default {};
</script>