<template>
	<div>
		<div
			:key="isLoading"
			v-show="!isLoading"
			id="home"
			:class="{ textBlur: menuOpen }"
			v-on:click.prevent="click"
			:aria-hidden="ariaHidden"
		>
			<div class="main-buttons-group">
				<X1Remote v-if="!getFlex"></X1Remote>
				<FlexRemote v-if="getFlex"></FlexRemote>
				<div class="bottom-functions">
					<div class="remote-custom-buttons">
						<template v-for="(seaButton, i) in rightCommands">
							<button
								class="btn"
								v-if="i <= 8"
								:key="i"
								:aria-label="seaButton.displayString"
								:data-command="seaButton.command"
							>
								<img
									:class="{'soft-img': seaButton.isLegacyIcon}"
									:alt="seaButton.command"
									:src="safeImage(seaButton.image)"
								/>
								<label aria-hidden="true" v-html="seaButton.displayString"></label>
							</button>
						</template>
					</div>
					<ViewAll :show-me="getViewAll"/>
				</div>
			</div>
		</div>
		<div id="sounds" style="display: none">
			<audio
				v-for="sound in soundsAudio"
				:key="sound.id"
				:id="sound.id"
				:src="sound.src"
			>
				{{ $t('browserAudioSupport') }}
			</audio>
		</div>
	</div>
</template>

<script>
import remoteMixin from './mixins/remote';
import X1Remote from './X1Remote.vue';
import FlexRemote from './FlexRemote.vue';
import ViewAll from './buttons/ViewAll.vue';

export default {
	name: 'Remote',
	mixins: [remoteMixin],
	components: {
    X1Remote,
    FlexRemote,
    ViewAll
},
	props: ['shortcutLink'],
	data: function () {
		return {
			isFlex: false,
			isLoading: true,
			rightCommands: [],
		};
	},
	methods: {
		safeImage: function (imageSrc) {
			return imageSrc || '/images/seabuttons/missingImage.png';
		}
	},
	computed: {
		getFlex: function () {
			return this.isFlex;
		},
		getViewAll: function () {
			return this.rightCommands.length > 0;
		}
	},
	mounted: function () {
		this.$nextTick(function () {
			const self = this;
			/*
			ar.getAccount(() => {
				self.isFlex = false;
				self.isLoading = false;
			});
			*/
			self.isLoading = false;
			// Retrieve available popular buttons by partner.
			// Catch for popular buttons on app init.
			ar.getPopularSeaButtons();

			// Fill right list of user selected custom buttons, fetching if necessary (not typical)
			if (!ar.state.haveSeaButtonsData) {
				ar.getSeaButtonsData(function () {
					if (ar.state.seaButtonsData.length > 0) {
						self.rightCommands = JSON.parse(JSON.stringify(ar.state.seaButtonsData)); // Deep copy
					}
				});
			} else {
				if (ar.state.seaButtonsData.length > 0) {
					self.rightCommands = JSON.parse(JSON.stringify(ar.state.seaButtonsData)); // Deep copy
				}
			}
		});
	},
};
</script>

<style lang="scss" scoped>
.main-buttons-group {
	display: flex;
	flex-direction: column;
	align-self: center;
}
.remote-custom-buttons {
	display: grid;
	grid-gap: 0.5rem;
	grid-template-columns: auto auto auto;
	margin-bottom: 0.5rem;

	@include lg {
		display: flex;
		margin-right: 0.5rem;
	}
}

.bottom-functions {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 4rem;

	@include lg {
		flex-direction: row;
	}
}
</style>
